.Usr {
    height:100vh;
    background-color:#f5f5f5 ;
}
  .Headerusr {
    height: auto;
    width:100%;
    padding-bottom: 10px;
    background-color:#b1dced ;
  
    display: flex;
    flex-direction:row ;
    justify-content: space-between;
    text-align: end;
  }
  .Userusr {
      margin:'0 0 0 0';
      width:'100%';
      display:inline-block;
  }

  .datatablecruddemo .tableheader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatablecruddemo .tableheader {
        align-items: flex-start;
    }
}
.datatablecruddemo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatablecruddemo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatablecruddemo .confirmationcontent {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatablecruddemo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatablecruddemo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatablecruddemo .tableheader {
        flex-direction: column;
    }
    .datatablecruddemo .tableheader .p-input-icon-left, .datatablecruddemo .tableheader input {
        width: 100%;
    }
}
.TC {
    background-color: rgb(241, 244, 248);
    position:relative;
    width: 80%;
    margin: 0 auto;
    
}
.Sform {
    
    position:relative;
    
    background-color: rgb(241, 244, 248);
    width: 80%;
    height: 8vh;

    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}
.tableheader {
    height:auto;
    display: flex;
    justify-content:center;
}