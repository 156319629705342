.datatablecruddemo .tableheader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatablecruddemo .tableheader {
        align-items: flex-start;
    }
}
.datatablecruddemo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.datatablecruddemo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
.datatablecruddemo .confirmationcontent {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 960px) {
    .datatablecruddemo .p-toolbar {
        flex-wrap: wrap;
    }
    .datatablecruddemo .p-toolbar .p-button {
        margin-bottom: 0.25rem;
    }
    .datatablecruddemo .tableheader {
        flex-direction: column;
    }
    .datatablecruddemo .tableheader .p-input-icon-left, .datatablecruddemo .tableheader input {
        width: 100%;
    }
}
.PER {
    height:100vh;
    font-size: small;
  }
  .Headerper {
      height: 40vh;
      width:100%;
      background-color:#B1DCED ;
      display: flex;
      flex-direction:row ;
      justify-content: space-between;
      text-align: end;
    }
  .Userper {
      margin:'0 0 0 0';
      width:'100%';
      display:inline-block;
  }
