.QI {
    height:100vh;
    font-size: small;
  }
  .Headerqi {
      height: 40vh;
      width:100%;
      background-color:#B1DCED ;
      display: flex;
      flex-direction:row ;
      justify-content: space-between;
      text-align: end;
    }
  .Userqi {
      margin:'0 0 0 0';
      width:'100%';
      display:inline-block;
  }
  .TCqi {
    background-color: rgb(241, 244, 248);
    z-index: 1;
    top:-25vh;
    position: relative;
    width: 80%;
    margin: 0 auto;
    align-self: center;
}

.MRD .p-dialog-footer  {
  padding: 0;
}

.MRD .p-dialog-header  {
  padding: 0;
  background:orange;
}