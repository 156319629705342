.PROM {
    height:100vh;
    font-size:smaller;
  }
.Headerm {
    height: 40vh;
    width:100%;
    background-color:#B1DCED ;
    display: flex;
    flex-direction:row ;
    justify-content: space-between;
    text-align: end;
  }
.Userm {
    margin:'0 0 0 0';
    width:'100%';
    display:inline-block;
  }

.TCm {
    background-color: rgb(241, 244, 248);
    z-index: 1;
    position:relative;
    top:-26vh;
    width: 80%;
    margin: 0 auto;
    align-self: center;
}
.Sformm {
    z-index: 1;
    position:relative;
    top:-25vh;
    background-color: rgb(241, 244, 248);
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}
.Sformpoitemm {
  z-index: 1;
  position:relative;
  top:-25vh;
  background-color: rgb(241, 244, 248);
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
}
.p-datatable-footer {
  padding-right: 0%;
}
