.wAppacc {
    display: flex;
    flex-direction: column;
  }
  .wApp-mainacc {
      width: 100%;
    }
    .wApp-titleacc {
      color:  #3F4254;
      display: inline-block;
      text-align: center;
      padding: 0%;
      margin: 0%;
    }
    .wApp-loginacc {
      width: 40%;
      height:100vh;
      float: left;
      display: flex;
      flex-direction:column;
      justify-content:safe;
      align-items: center;
      text-align: center;
      
    }
    .wApp-messageacc {
      width: 60%;
      height:100vh;
      float: right;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color:#B1DCED;
  
      
    }
    