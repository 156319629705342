.AR {
    height:100vh;
    font-size: small;
  }
  .headpr {
    height: 40vh;
    width:100%;
    background-color:#B1DCED ;
    display: flex;
    flex-direction:row ;
    justify-content: space-between;
    text-align: end;
  }
  .Headerar {
      height: 40vh;
      width:100%;
      background-color:#B1DCED ;
      display: flex;
      flex-direction:row ;
      justify-content: space-between;
      text-align: end;
    }
  .Userar {
      margin:'0 0 0 0';
      width:'100%';
      display:inline-block;
  }
  .TCar {
    background-color: rgb(241, 244, 248);
    z-index: 1;
    position:relative;
    top:-25vh;
    width: 80%;
    
    margin: 0 auto;
    align-self: center;
}
.Sformar {
    z-index: 1;
    position:relative;
    top:-25vh;
    background-color: rgb(241, 244, 248);
    width: 80%;
    height: 8vh;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}
.table-headerar {
    height:auto;
    display: flex;
    justify-content:center;
}  
.Statuslog {
    width:60%;
    height: auto;
    margin:10px auto;
    display:block;
}
