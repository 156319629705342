.tableheader {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}
@media screen and (max-width: 960px) {
    .datatablecruddemo .tableheader {
        align-items: flex-start;
    }
}
.datatablecruddemo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}
@media screen and (max-width: 960px) {
    .datatablecruddemo  {
        flex-wrap: wrap;
    }
    .datatablecruddemo  .p-button {
        margin-bottom: 0.25rem;
    }
    .datatablecruddemo .tableheader {
        flex-direction: column;
    }
    .datatablecruddemo .tableheader .p-input-icon-left, .datatablecruddemo .tableheader input {
        width: 100%;
    }
}
.PER {
    height:100vh;
    font-size: small;
}
.Headerper {
    height: 40vh;
    width:100%;
    background-color:#B1DCED ;
    display: flex;
    flex-direction:row ;
    justify-content: space-between;
    text-align: end;
}
.Userper {
    margin:'0 0 0 0';
    width:'100%';
    display:inline-block;
}
.TC {
    background-color: rgb(241, 244, 248);
    position:relative;
    top:-27vh;
    width: 95%;
    margin: 0 auto;
    align-self: center;
}
.Sform {
    position:relative;
    top:-27vh;
    background-color: rgb(241, 244, 248);
    width: 95%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:center;
}
